import { Stack } from "@mui/material";
import { WorkerShiftCardSkeleton } from "@src/appV2/redesign/Shift/WorkerShift/CardSkeleton";

import { HighlightedWorkerCardSkeleton } from "../../Shift/WorkerShift/HighlightedCardSkeleton";

export function LoadingPageState() {
  return (
    <Stack spacing={4}>
      <HighlightedWorkerCardSkeleton />
      <WorkerShiftCardSkeleton />
      <WorkerShiftCardSkeleton />
    </Stack>
  );
}
