import { Callout } from "@clipboard-health/ui-components";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { SENT_HOME_REQUESTS_FULL_PATH } from "../path";

interface PendingSentHomeRequestsCalloutProps {
  sentHomeRequestsCount: number;
}

export function PendingSentHomeRequestsCallout({
  sentHomeRequestsCount,
}: PendingSentHomeRequestsCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${sentHomeRequestsCount} pending sent home ${pluralize(
        "request",
        sentHomeRequestsCount
      )}`}
      iconType="document"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.secondary,
      })}
      onClick={() => {
        history.push(SENT_HOME_REQUESTS_FULL_PATH);
      }}
    />
  );
}
