import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { type Bookings } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useCheckIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useCheckIsOnCallWorkplace";
import { generatePath } from "react-router-dom";

import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { WorkerShiftCard } from "../../Shift/WorkerShift/Card";
import { OnCallWorkerShiftCard } from "../../Shift/WorkerShift/OnCall/ShiftCard";
import { getShiftWorkDurationInHours } from "../../utils/getShiftWorkDuration";
import { useIsWorkerShiftsTimekeepingRedesignEnabled } from "../api/useIsWorkerShiftsRedesignEnabled";
import { SHIFT_DETAILS_FULL_PATH } from "../path";
import { WorkerInterviewCard } from "./WorkerInterviewCard";

interface ScheduledBookingsProps {
  bookings: Bookings[];
}

export function ScheduledBookings(props: ScheduledBookingsProps) {
  const { bookings } = props;

  const isWorkerShiftsTimekeepingPageRedesignEnabled =
    useIsWorkerShiftsTimekeepingRedesignEnabled();

  const checkIsOnCallShift = useCheckIsOnCallWorkplace();

  return (
    <Stack spacing={4}>
      {bookings.length > 0 && (
        <Stack direction="row" alignItems="center" spacing={4} sx={{ paddingX: 2 }}>
          <Title component="h2" variant="h5" paddingY={3}>
            Scheduled{" "}
          </Title>
          <Text semibold variant="body2" color={(theme) => theme.palette.text.tertiary}>
            {bookings.length}
          </Text>
        </Stack>
      )}

      {bookings.map((booking) => {
        if (booking.type === "shift") {
          const shift = booking.data;
          const payBreakdown = getFormattedShiftPayBreakdown(
            shift.offer?.pay.value,
            shift.finalPay,
            shift.originalAmount
          );
          const href = isWorkerShiftsTimekeepingPageRedesignEnabled
            ? generatePath(SHIFT_DETAILS_FULL_PATH, {
                shiftId: shift._id,
              })
            : generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
                shiftId: shift._id,
              });

          if (checkIsOnCallShift(shift.facility.userId)) {
            return (
              <OnCallWorkerShiftCard
                key={shift._id}
                href={href}
                workplaceTimezone={shift.facility.tmz ?? ""}
                shiftTimeSlot={shiftPeriodToTimeSlot[shift.name]}
                shiftStart={shift.start}
                shiftEnd={shift.end}
                shiftQualificationName={shift.agentReq}
                shiftHourlyPay={payBreakdown?.hourlyPay}
              />
            );
          }

          return (
            <WorkerShiftCard
              key={shift._id}
              href={href}
              workplaceName={shift.facility.name}
              workplaceTimezone={shift.facility.tmz ?? ""}
              shiftTimeSlot={shiftPeriodToTimeSlot[shift.name]}
              shiftStart={shift.start}
              shiftEnd={shift.end}
              shiftQualificationName={shift.agentReq}
              shiftHourlyPay={payBreakdown?.hourlyPay}
              shiftTotalPay={payBreakdown?.totalPay}
              shiftWorkDurationInHours={getShiftWorkDurationInHours(
                { start: shift.start, end: shift.end },
                shift.facility.requiresLunchBreak ?? false
              )}
            />
          );
        }

        if (booking.type === "interview") {
          return (
            <WorkerInterviewCard
              key={booking.data.id}
              interviewId={booking.data.id}
              start={booking.data.start}
              end={booking.data.end}
              status={booking.data.status}
              placementId={booking.data.placementId}
              workerTypes={booking.data.placement?.workerTypes ?? []}
              workplaceName={booking.data.workplace?.name ?? ""}
              workplaceDistance={booking.data.workplace?.distance}
            />
          );
        }

        return null;
      })}
    </Stack>
  );
}
