import { Card } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { Button } from "@src/appV2/redesign/components/Button";

import { SHIFT_DISCOVERY_LIST_PATH } from "../../ShiftDiscovery/paths";
import { useIsShiftDiscoveryEnabled } from "../../ShiftDiscovery/useIsShiftDiscoveryEnabled";
import emptyCardState from "../assets/images/empty-state-card-bg.png";

export function EmptyPageState() {
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  const href = isShiftDiscoveryEnabled
    ? `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`
    : DeprecatedGlobalAppV1Paths.OPEN_SHIFTS;

  return (
    <Card
      sx={(theme) => ({
        borderRadius: 4,
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${emptyCardState})`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
      })}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{
          textAlign: "center",
          flex: 1,
          paddingBottom: 10,
        }}
      >
        <Stack spacing={4}>
          <Title semibold variant="h4" component="h4">
            You don&apos;t have
            {/* eslint-disable-next-line no-restricted-syntax */}
            <br />
            any bookings
          </Title>

          <Text semibold variant="body2">
            Browse shifts near you
          </Text>
        </Stack>
        <Box sx={{ paddingTop: 6 }}>
          <Button invert variant="contained" size="medium" href={href}>
            Browse shifts
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}
