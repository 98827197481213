import { Callout } from "@clipboard-health/ui-components";
import { useHistory } from "react-router-dom";

import { BREAK_PAYMENT_REQUESTS_FULL_PATH } from "../path";

interface PendingBreakPaymentRequestsCalloutProps {
  extraWorkedRequestsCount: number;
}

export function PendingBreakPaymentRequestsCallout({
  extraWorkedRequestsCount,
}: PendingBreakPaymentRequestsCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${extraWorkedRequestsCount} pending break payment requests`}
      iconType="break"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.secondary,
      })}
      onClick={() => {
        history.push(BREAK_PAYMENT_REQUESTS_FULL_PATH);
      }}
    />
  );
}
