import { Box, Stack } from "@mui/material";
import { HomeHealthBookedCasesWrapper } from "@src/appV2/ExperimentalHomeHealth/BookedCases/HomeHealthBookedCasesWrapper";
import { type Bookings } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";

import { EmptyPageState } from "./EmptyPageState";
import { HighlightedBookingSection } from "./HighlightedBookingSection";
import { LoadingPageState } from "./LoadingPageState";
import { ScheduledBookings } from "./ScheduledBookings";

interface BookingsContainerProps {
  isLoading: boolean;
  bookings: Bookings[];
  highlightedBooking?: Bookings;
  shiftPayBreakdown?: {
    hourlyPay?: string;
    totalPay?: string;
  };
  currentDate: Date;
  isWorkerShiftsTimekeepingPageRedesignEnabled: boolean;
  showEmptyShiftState: boolean;
}

export function BookingsContainer(props: BookingsContainerProps) {
  const {
    isLoading,
    bookings,
    highlightedBooking,
    shiftPayBreakdown,
    currentDate,
    isWorkerShiftsTimekeepingPageRedesignEnabled,
    showEmptyShiftState,
  } = props;
  if (showEmptyShiftState) {
    return (
      <Box width="100%" height="100%" sx={{ marginTop: 4 }}>
        <EmptyPageState />
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: 4 }}>
      {isLoading && <LoadingPageState />}

      {!isLoading && (
        <Stack spacing={4}>
          <HighlightedBookingSection
            highlightedBooking={highlightedBooking}
            shiftPayBreakdown={shiftPayBreakdown}
            currentDate={currentDate}
            isWorkerShiftsTimekeepingPageRedesignEnabled={
              isWorkerShiftsTimekeepingPageRedesignEnabled
            }
          />

          <ScheduledBookings bookings={bookings} />
          <HomeHealthBookedCasesWrapper />
        </Stack>
      )}
    </Box>
  );
}
