import { Card, CbhIcon, Pill } from "@clipboard-health/ui-components";
import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CardActionArea, Stack, useTheme } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { type InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { differenceInMinutes } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { generatePath } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { getPlacementDetailsPathWithoutPlacementCandidate } from "../../Placements/paths";
import { BookingCardContentWrapper } from "../../Shift/CardContentWrapper";
import { WorkplaceNameWithDistance } from "../../Shift/Open/WorkplaceNameWithDistance";
import { BookingCardSummaryWrapper } from "../../Shift/SummaryWrapper";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { formatDistance } from "../../Workplace/useDistanceToWorkplace";

interface InterviewCardFooterProps {
  workerTypes: string[];
}

function InterviewCardFooter({ workerTypes }: InterviewCardFooterProps) {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3} alignItems="center">
      {workerTypes.length > 0 && (
        <Pill
          size="small"
          label={workerTypes.join(", ")}
          variant="outlined"
          sx={{ width: "fit-content" }}
        />
      )}
      <Text variant="body2" color={theme.palette.intent?.success.text}>
        Get $20 to cover transportation costs
      </Text>
    </Stack>
  );
}

export interface WorkerInterviewCardProps {
  interviewId: string;
  start: Date;
  end: Date;
  status: InterviewStatus;
  placementId: string;
  workerTypes: string[];
  workplaceName: string;
  workplaceDistance?: number;
}

export function WorkerInterviewCard(props: WorkerInterviewCardProps) {
  const {
    interviewId,
    start,
    end,
    status,
    placementId,
    workerTypes,
    workplaceName,
    workplaceDistance,
  } = props;
  const theme = useTheme();
  const worker = useDefinedWorker();
  const durationInMinutes = differenceInMinutes(end, start);

  const interviewDetailsPath = generatePath(
    getPlacementDetailsPathWithoutPlacementCandidate(placementId)
  );
  return (
    <Card outlined variant="tertiary" sx={{ flexGrow: 1 }}>
      <CardActionArea
        component={InternalLink}
        to={interviewDetailsPath}
        onClick={() => {
          logEvent(APP_V2_APP_EVENTS.BOOKED_INTERVIEW_CLICKED, {
            workerId: worker.userId,
            interviewDetails: {
              start: zonedTimeToUtc(start, worker.tmz).toISOString(),
              end: zonedTimeToUtc(end, worker.tmz).toISOString(),
              status,
              id: interviewId,
            },
            placementDetails: {
              id: placementId,
              workerTypes,
            },
          });
        }}
      >
        <BookingCardContentWrapper>
          <BookingCardSummaryWrapper>
            <Box
              sx={(theme) => ({
                display: "inline-flex",
                alignItems: "center",
                padding: 7,
                borderRadius: theme.borderRadius?.small,
                flexShrink: 0,
                background: theme.palette.intent?.success.gradient,
              })}
            >
              <CbhIcon type="briefcase" />
            </Box>
            <Stack spacing={3}>
              <Pill
                size="small"
                label="Interview Scheduled"
                variant="outlined-dark"
                iconType="checkmark"
                sx={{
                  width: "fit-content",
                }}
              />
              <Text semibold variant="h6">
                {formatDayOfMonthWithDayName(start)}
              </Text>

              <Stack
                direction="row"
                sx={{ flexWrap: "wrap", columnGap: 3, alignItems: "baseline" }}
              >
                <TimeRangeLabel
                  semibold
                  dateRange={{ startDate: start, endDate: end }}
                  variant="h6"
                />

                <Text variant="body2" sx={(theme) => ({ color: theme.palette.text.tertiary })}>
                  {durationInMinutes} mins
                </Text>
              </Stack>

              <WorkplaceNameWithDistance
                workplaceName={workplaceName}
                formattedDistance={
                  isDefined(workplaceDistance) ? formatDistance(workplaceDistance) : undefined
                }
              />
            </Stack>
          </BookingCardSummaryWrapper>

          <InterviewCardFooter workerTypes={workerTypes} />

          <IconButton
            aria-label="Go to interview details"
            size="xSmall"
            variant="outlined"
            iconType="chevron-right"
            sx={{ position: "absolute", right: theme.spacing(6), top: theme.spacing(6) }}
          />
        </BookingCardContentWrapper>
      </CardActionArea>
    </Card>
  );
}
