import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { WorkerShiftsRedesignRolloutStage } from "@src/appV2/FeatureFlags/types";

export function useIsWorkerShiftsRedesignEnabled(): boolean {
  const result = useCbhFlag(CbhFeatureFlag.WORKER_SHIFTS_REDESIGN_ROLLOUT_STAGE, {
    defaultValue: { stage: WorkerShiftsRedesignRolloutStage.OFF },
  });

  return [
    WorkerShiftsRedesignRolloutStage.WORKER_SHIFTS,
    WorkerShiftsRedesignRolloutStage.WORKER_SHIFTS_TIMEKEEPING,
  ].includes(result.stage);
}

export function useIsWorkerShiftsTimekeepingRedesignEnabled(): boolean {
  const workerShiftsStage = useCbhFlag(CbhFeatureFlag.WORKER_SHIFTS_REDESIGN_ROLLOUT_STAGE, {
    defaultValue: { stage: WorkerShiftsRedesignRolloutStage.OFF },
  });
  return workerShiftsStage.stage === WorkerShiftsRedesignRolloutStage.WORKER_SHIFTS_TIMEKEEPING;
}
