import { Box } from "@mui/material";
import { forwardRef } from "react";

interface PageContentProps {
  children: React.ReactNode;
}

function PageContentBase(props: PageContentProps, ref: React.Ref<HTMLDivElement>) {
  const { children } = props;
  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingX: 5,
        paddingBottom: 4,
        overflowY: "auto",
        overflowX: "hidden",
        flexGrow: 1,
      }}
    >
      {children}
    </Box>
  );
}

export const PageContent = forwardRef(PageContentBase);

PageContent.displayName = "PageContent";
