import { Callout } from "@clipboard-health/ui-components";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { SHIFT_INVITES_FULL_PATH } from "../path";

interface ShiftInviteCalloutProps {
  shiftInviteCount: number;
}

export function ShiftInviteCallout({ shiftInviteCount }: ShiftInviteCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${shiftInviteCount} ${pluralize("invite", shiftInviteCount)}`}
      iconType="invite"
      sx={(theme) => ({
        color: theme.palette.shift?.invite.text,
        backgroundColor: theme.palette.shift?.invite.background,
      })}
      onClick={() => {
        history.push(SHIFT_INVITES_FULL_PATH);
      }}
    />
  );
}
