import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// eslint-disable-next-line no-restricted-imports
import { Card, CardContent, Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";
import { type ShiftPeriod, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { parseISO } from "date-fns";
import { type ReactNode } from "react";

import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { TimeSlotIndicator } from "../../Shift/TimeSlotIndicator";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";

interface WorkerShiftRequestCardProps {
  shiftTimeSlot: ShiftPeriod;
  shiftStart: string;
  shiftEnd: string;
  pills?: ReactNode[];
  children?: ReactNode;
  details?: ReactNode;
}

export function WorkerShiftRequestCard(props: WorkerShiftRequestCardProps) {
  const { shiftTimeSlot, shiftStart, shiftEnd, pills, children, details } = props;

  const dateRange: DateRange = { startDate: parseISO(shiftStart), endDate: parseISO(shiftEnd) };
  const deviceTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardContent sx={{ position: "relative", padding: 6 }}>
        <Stack spacing={6}>
          {isDefined(pills) && (
            <Stack direction="row" spacing={2}>
              {...pills}
            </Stack>
          )}
          <Stack direction="row" spacing={5} alignItems="flex-start" flexGrow={1}>
            <TimeSlotIndicator timeSlot={shiftPeriodToTimeSlot[shiftTimeSlot]} />
            <Stack spacing={3} alignItems="flex-start" flexGrow={1}>
              <Stack spacing={2}>
                <Text semibold variant="body2">
                  {formatDayOfMonthWithDayName(parseISO(shiftStart), { formatDayString: "EEEE" })}
                </Text>
                <TimeRangeLabel
                  variant="h6"
                  dateRange={dateRange}
                  deviceTimezone={deviceTimezone}
                />
              </Stack>
              <Stack spacing={1}>{details}</Stack>
            </Stack>
          </Stack>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
